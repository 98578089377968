<template>
  <Component :is="renderComponent" />
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRegistrationStore } from '@/stores/registration'
import { storeToRefs } from 'pinia'

import RegisterBusinessDetailsSTPFlow from './RegisterBusinessDetailsSTPFlow.vue'
import RegisterBusinessDetailsOldFlow from './RegisterBusinessDetailsOldFlow.vue'

export default {
  name: 'RegisterBusinessDetailsWrapper',
  components: {
    RegisterBusinessDetailsOldFlow,
    RegisterBusinessDetailsSTPFlow,
  },
  setup() {
    const registrationStore = useRegistrationStore()
    const { redirectToOldFlow } = storeToRefs(registrationStore)

    const renderComponent = computed(() => {
      return redirectToOldFlow.value
        ? 'RegisterBusinessDetailsOldFlow'
        : 'RegisterBusinessDetailsSTPFlow'
    })

    return {
      renderComponent,
    }
  },
}
</script>
