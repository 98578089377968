<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>Business details</h3>
      </AppCardHeader>
    </template>

    <div v-if="isLoadingOptions" class="text-center text-orange">
      <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
    </div>

    <form v-else @submit.prevent="submit">
      <AppDynamicForm :fields="formFields" :disabled="isLoading" />
      <div v-if="!isLiteRegistration" class="flex items-start mt-4 mb-6">
        <AppInputCheckbox v-model="signingAuthority" />
        <p class="text-sm text-gray pl-3.5">
          I confirm that I am a director, partner, manager or equivalent and I have the authority to
          sign on behalf of the company
        </p>
      </div>

      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading">
          Continue
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import {
  reactive,
  ref,
  toRefs,
  computed,
  watch,
  onBeforeMount,
  onMounted,
} from '@vue/composition-api'

import { useRouter } from '@/composables/useRouter'
import { useStore } from '@/composables/useStore'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'
import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'
import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import AppSpinner from '@/components/AppSpinner/AppSpinner'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'

export default {
  name: 'RegisterBusinessDetails',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    AppInputCheckbox,
    IconChevronLeft,
    AppDynamicForm,
    AppSpinner,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()

    const router = useRouter()
    const store = useStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const incorporationDate = ref(null)
    const isLoadingOptions = ref(true)

    const { isLiteRegistration } = storeToRefs(authStore)
    corporateRegistrationStore.activeStepIdx = 2
    corporateRegistrationStore.setActiveStepPageTitle('Business details')

    const fieldGroupName = 'company'
    const einFieldName = 'ustaxnumber'
    const fieldsToIgnore = [
      'companytype',
      'currencytype',
      'expectedannualtradingvolume',
      'expectedtotradein',
    ]

    const liteRegFieldsToIgnore = [
      'website',
      'tradingname',
      'ustaxnumber',
      'registrationnumber',
      'incorporationdate',
      'natureofbusiness',
      'industry',
    ]

    const businessDetail = reactive({ ...corporateRegistrationStore.businessDetail })
    const signingAuthority = ref(companyDetailsStore.signingAuthority)

    const isSubmitButtonDisabled = computed(() => {
      if (isLiteRegistration.value) {
        return !formFields.value[0].value
      }

      // Added isLoading value so the form is disabled during load operations
      return (
        !hasFormFields.value ||
        isFormInvalid.value ||
        incorporationDate.value ||
        !signingAuthority.value ||
        isLoading.value
      )
    })

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    // Get fields
    let fields = computed(() => corporateRegistrationStore.getNewCustomerFields(fieldGroupName))

    // Setup details fields
    const setupDetailFields = (fields) => {
      if (fields) {
        fields = fields.filter(({ id }) => !fieldsToIgnore.includes(id))
        if (isLiteRegistration.value) {
          fields = fields.filter(({ id }) => !liteRegFieldsToIgnore.includes(id))
        }
        setupFields(fields, fieldGroupName)
        const einField = formFields.value.find((field) => field.id === einFieldName)
        if (einField) {
          const einFieldRef = toRefs(einField)
          watch(einFieldRef.value, (newVal) => {
            if (newVal.length > 2 && newVal.charAt(2) !== '-') {
              einFieldRef.value.value = [newVal.slice(0, 2), '-', newVal.slice(2)].join('')
            }
          })
        }
      }
    }

    // Render only when fields exist
    watch(
      fields,
      () => {
        if (fields.value) {
          setupDetailFields(fields.value.fields)
          isLoadingOptions.value = false
        }
      },
      { immediate: true }
    )

    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }

    // Get specific field from form field object
    const getSpecificField = (id) => formFields.value.find((field) => field.id === id)

    // Get specific value from field object
    const getValueFromField = (fieldName, fieldId) => {
      if (getSpecificField(fieldName)) {
        return getSpecificField(fieldName).props.options.find(
          (option) => option.id === getFieldValue(fieldId)
        )
      }
    }

    // Delete leading & trailing spaces on input text after onblur event
    formFields.value.forEach((field, i) => {
      if (field.component.name === 'default' || field.component.name === '_default') {
        formFields.value[i]['listeners'] = {
          blur: (evt) => {
            if (field.value === evt.target.value) {
              field.value = cleanTrailingSpace(field.value)
            }
          },
        }
      }

      if (field.component.name === 'date') {
        formFields.value[i]['listeners'] = {
          validation: (componentValidation) => {
            incorporationDate.value = componentValidation['$anyInvalid']
          },
        }
      }
    })

    // Gets industry data
    const industryData = computed(() => getValueFromField('natureofbusiness', 'natureofbusiness'))
    const subIndustryField = getSpecificField('industry')
    const { subIndustryList } = storeToRefs(corporateRegistrationStore)

    // Handle Sub Industry updates with the industry data
    watch(industryData, async (field, oldField) => {
      // Checks for updated field
      if (field.id !== oldField) {
        subIndustryField.props.loading = true
        subIndustryField.value = null
        await corporateRegistrationStore.setSubIndustryList(field.id)
        subIndustryField.props.options = subIndustryList.value
        subIndustryField.props.loading = false
        subIndustryField.disabled = false
      } else {
        subIndustryField.disabled = true
      }
    })

    const submit = async () => {
      isLoading.value = true
      try {
        const errors = await corporateRegistrationStore.submitFieldData({
          group: 'company',
          formFields,
        })
        if (errors && errors.length > 0) {
          const duplicateCompany = errors.find(
            (error) =>
              error.id === 'registrationnumber' &&
              error.errors.find((suberror) => suberror.code === 'DUPCOMPANY_005')
          )
          if (duplicateCompany) {
            // Send event if company is duplicate
            analyticsStore.track({
              event: 'Duplicate account error triggered',
              traits: {
                code: duplicateCompany.errors.find((error) => error).code,
                description: duplicateCompany.errors.find((error) => error).description,
              },
            })
            router.push({
              name: 'RegisterError',
              params: {
                errorType: 'duplicate',
              },
            })
            return
          }
          const natureOfBusiness = errors.find((error) => error.id === 'natureofbusiness')
          if (natureOfBusiness) {
            router.push({
              name: 'RegisterError',
              params: {
                errorType: 'natureOfBusiness',
              },
            })
            return
          }
          corporateRegistrationStore.showServerErrors({ errors, formFields })
        } else {
          let registrationNumber = getFieldValue('ustaxnumber')
          if (!registrationNumber) {
            registrationNumber = getFieldValue('registrationnumber')
          }

          if (!isLiteRegistration.value) {
            analyticsStore.identify({
              userId: authStore.profileId,
              traits: {
                industry: getSpecificField('natureofbusiness').props.options.find(
                  (option) => option.id === getFieldValue('natureofbusiness')
                ).name,
                subIndustry: getSpecificField('industry').props.options.find(
                  (option) => option.id === getFieldValue('industry')
                ).name,
                registrationNumber: registrationNumber,
                registrationName: getFieldValue('registeredname'),
              },
            })
          }

          analyticsStore.track({
            event: 'Business Details Completed',
            traits: {
              registrationNumber: registrationNumber,
              registrationName: getFieldValue('registeredname'),
              referenceSource: 'XEMT Business',
              website: getFieldValue('website'),
              dateOfIncorporation: getFieldValue('incorporationdate'),
              email: authStore.lastLogin,
            },
          })
          corporateRegistrationStore.businessDetail = businessDetail

          const registeredAddress = corporateRegistrationStore.getCorporateForm('registeredaddress')
          // Clean null, empty, and/or undefined objects
          let cleanRegisteredAddress
          if (
            typeof registeredAddress === 'object' &&
            registeredAddress !== null &&
            registeredAddress !== undefined &&
            Object.keys(registeredAddress).length > 0
          ) {
            cleanRegisteredAddress = Object.values(registeredAddress).filter(
              (item) => item.value !== null && item.value !== '' && item.value !== undefined
            )
          }
          // Router condition
          if (isLiteRegistration.value) {
            router.push({ name: 'RegisterPersonalDetails' })
          } else if (
            registeredAddress &&
            cleanRegisteredAddress.length > 1 &&
            corporateRegistrationStore.getCorporateForm('company')
          ) {
            await router.push({ name: 'RegisterAddressSearch' })
            router.push({ name: 'BusinessAddress' })
          } else {
            router.push({ name: 'RegisterAddressSearch' })
          }
        }
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submiting business details',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      isLoading.value = false
    }

    const goBack = () => {
      if (isLiteRegistration.value) {
        router.replace({ name: 'RegisterBusinessInformation' })
      } else {
        router.replace({ name: 'RegisterBusinessSearch' })
      }
    }

    onBeforeMount(async () => {
      if (!corporateRegistrationStore.userId) {
        await corporateRegistrationStore.fetchNewCustomerRequiredFields()
      }
    })

    onMounted(() => {
      // Check if industry type is selected and hiddens the subIndustry
      if (!isLiteRegistration.value) {
        if (industryData.value && Object.keys(industryData.value).length > 0) {
          corporateRegistrationStore.setSubIndustryList(industryData.value.id)
        } else {
          subIndustryField.disabled = true
        }
      }
    })

    return {
      isLiteRegistration,
      signingAuthority,
      isSubmitButtonDisabled,
      submit,
      formFields,
      subIndustryList,
      goBack,
      isLoading: computed(() => isLoading.value),
      mq: reactive(useMediaQuery()),
      incorporationDate,
      isLoadingOptions,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-input-checkbox {
  z-index: 0;
}
</style>
