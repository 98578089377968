<template>
  <AppCard class="card-stp business-details-card">
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ stepConfig.mainTitle }}</h3>
      </AppCardHeader>
    </template>

    <!-- mobile only -->
    <h3 class="type-h2 pb-4 sm:hidden">{{ stepConfig.mainTitle }}</h3>

    <form @submit.prevent="submit">
      <XeAlertBox type="warning" title="Manual entry delays approval">
        This process will be carried out manually and will delay your account approval
      </XeAlertBox>
      <AppDynamicForm :fields="formFields" :disabled="isLoading" data-public />

      <!-- TODO: Create component for custom address -->
      <!-- Address Field -->
      <template v-if="!isSelectedAddress">
        <!-- Address search -->
        <XeAddressSearch
          data-public
          :dropdown-label="'Registered business address'"
          :is-manually-click="true"
          :show-manual-input-prompt="true"
          :country="companyCountry"
          :is-allow-free-input="true"
          :validation-error-message="addressErrorMessage"
          @input="onInput"
          @select="onSelect"
        />
      </template>

      <!-- Selected Address -->
      <template v-else>
        <div class="p-6 mb-6 border border-solid rounded-2xl border-tertiary-grey-darkest">
          <div class="flex">
            <div class="flex flex-col">
              <AppIcon name="Location">
                <IconLocation class="text-main-cyan" />
              </AppIcon>
            </div>
            <div class="ml-2 flex-grow">
              <h3 class="type-subtitle-bold">Registered business address</h3>
              <p class="pt-1 type-caption text-gray-primary">
                {{ formattedAddress }}
              </p>
            </div>
            <div class="flex flex-col justify-center cursor-pointer" @click="onAddressClear">
              <AppIcon name="Edit">
                <IconClose class="text-gray-primary" />
              </AppIcon>
            </div>
          </div>
        </div>
      </template>
      <!-- TODO: Create component for custom address -->

      <!-- Marketing Opt-in checkbox -->
      <div v-if="allowMarketingOptIn" class="opt-in-div">
        <div>
          <AppInputCheckbox v-model="marketingOptIn" class="sub-opt-in-div-left" />
          <div class="sub-card-right">
            <p class="type-caption text-gray">
              {{ stepConfig.mkOptInText.description }}
            </p>
          </div>
        </div>
      </div>

      <XeAlertBox type="info">
        By confirming, you affirm you're a Director (or equivalent) {{ authorizedStr }} to agree to
        our
        <AppLink class="type-caption" :href="termsUrl" :target="target">Terms & Conditions</AppLink>
        and
        <AppLink class="type-caption" :href="privacyUrl" :target="target">Privacy Policy</AppLink>
        on your businesses behalf.
      </XeAlertBox>
      <div class="flex flex-col justify-center">
        <AppButton
          type="submit"
          class="submit-button"
          :disabled="isSubmitButtonDisabled"
          :loading="submitting ? submitting : isLoading"
        >
          {{ stepConfig.buttonText.submit }}
        </AppButton>
      </div>
    </form>
  </AppCard>
</template>

<script>
import { computed, reactive, onBeforeMount, ref, watch } from '@vue/composition-api'

import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'
import { useAddressFormat } from '@/composables/useAddress'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'

import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'

import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import XeAddressSearch from '@/components/XeAddressSearch/XeAddressSearch'
import XeAlertBox from '@/components/XeAlertBox/XeAlertBox'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox'
import AppLink from '@/components/AppLink/AppLink'

import { IconChevronLeft, IconClose, IconLocation } from '@moneytransfer.ui/euronet-icons'
import { getFieldValue } from '@/utils/form'
import { regNumberBeforeDoi } from '@/helpers/companyDetails'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'
import { useCountriesStore } from '@/stores/countries'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'RegisterBusinessDetailsSTP',
  components: {
    AppCard,
    AppCardHeader,
    AppDynamicForm,
    AppIcon,
    AppButton,
    XeAddressSearch,
    XeAlertBox,
    IconChevronLeft,
    IconClose,
    IconLocation,
    AppInputCheckbox,
    AppLink,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    const { stpConfig } = storeToRefs(registrationStore)

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    // Set steps for STP
    corporateRegistrationStore.steps = stpConfig.value.registrationSteps

    // Get STP config
    const stepConfig = registrationStore.getRegistrationStepConfigById('company')

    // Set default variables
    const fieldGroupName = ref('company')
    const incorporationDate = ref(null)
    const companyCountry = companyDetailsStore.companyCountry
    const companyType = companyDetailsStore.companyType
    const submitting = ref(false)
    const authorizedStr = computed(() =>
      ['US', 'CA'].includes(companyCountry) ? 'authorized' : 'authorised'
    )

    // Values for address field
    const isSelectedAddress = ref(false)
    const isAddressReady = ref(false)
    const addressErrorMessage = ref('')
    const formattedAddress = ref('')
    const companyAddress = ref([])
    // Values for T&C disclaimer
    const termsUrl = ref(null)
    const privacyUrl = ref(null)
    const target = ref(null)
    const termsVersion = ref(null)
    const marketingOptIn = ref(true)
    const allowMarketingOptIn = ref(null)
    const receiveDocuments = ref(false)

    // Populate address search
    if (registrationStore.formattedAddress.length > 0) {
      formattedAddress.value = registrationStore.formattedAddress
      isSelectedAddress.value = true
      isAddressReady.value = true
    }

    // Setup everything to render properly
    onBeforeMount(async () => {
      // Tell the store which step we are on
      corporateRegistrationStore.setActiveStep(router.currentRoute.path)
      corporateRegistrationStore.activeStepIdx = stepConfig.stepId
      corporateRegistrationStore.setActiveStepPageTitle(stepConfig.stepTitle)

      // Check company type was changed
      if (companyDetailsStore.hasCompanyTypeChanged) {
        // Clear company fields
        corporateRegistrationStore.setClearCustomerFieldsGroup(fieldGroupName.value)
        // After cleaning fields setup company type flag as a false
        companyDetailsStore.hasCompanyTypeChanged = false
      }

      // Setup fields
      let { fields } = corporateRegistrationStore.getNewCustomerFields(fieldGroupName.value)
      fields = fields.filter(({ id }) => !stepConfig.fieldsToIgnore.includes(id))
      // Check and fix order of DOI and RegNumber to match figma
      fields = regNumberBeforeDoi(fields)
      // Setup custom data and validation
      const stpFields = corporateRegistrationStore.getMockedCustomerFields(fieldGroupName.value)
      fields = fields.map((field) => {
        if (field.id === 'registeredname') {
          const selectedStpField = stpFields.fields.find((item) => field.id === item.id)
          field.validations = selectedStpField.validations
        }
        if (field.id === 'registrationnumber') {
          field.validations.map((i) => (i.message = stepConfig.customValidationMessage.default))
        }
        return field
      })
      // Render fields
      if (fields) {
        setupFields(fields, fieldGroupName.value)
        // Delete leading & trailing spaces on input text after onblur event
        formFields.value.forEach((field, i) => {
          if (field.component.name === 'default' || field.component.name === '_default') {
            formFields.value[i]['listeners'] = {
              blur: (evt) => {
                if (field.value === evt.target.value) {
                  field.value = cleanTrailingSpace(field.value)
                }
              },
            }
          }
          // Update field details
          if (field.id === 'registeredname') {
            field.label = stepConfig.customFieldLabel.registeredname
            field.props.placeholder = stepConfig.customFieldLabel.placeholder
          }
          if (field.component.name === 'date') {
            formFields.value[i]['listeners'] = {
              validation: (componentValidation) => {
                incorporationDate.value = componentValidation['$anyInvalid']
              },
            }
          }

          // Set fields values
          const selectedSavedField = corporateRegistrationStore.getCorporateFormSavedValue(
            'company',
            field.id
          )
          if (field.id === 'incorporationdate' && !selectedSavedField) {
            // Set empty string to display field error
            field.value = ''
          } else if (field.id === 'incorporationdate') {
            // Set formatted date to be read by field validation
            field.value = new Date(selectedSavedField).toISOString().split('T')[0]
          } else if (selectedSavedField) {
            // Set saved values from CorporateFormSavedValue state
            field.value = selectedSavedField
          }
        })
      }

      // Set T&C disclaimer
      try {
        const result = await corporateRegistrationStore.fetchTermsAndConditions()
        termsUrl.value = result.url
        privacyUrl.value = result.privacyUrl
        termsVersion.value = result.version
        target.value = '_blank'
        receiveDocuments.value = true

        await corporateRegistrationStore.fetchMarketingPrompt({
          countryCode: companyCountry,
          companyTypeId: Number(companyType),
        })
      } catch (ex) {
        appStore.logException({
          text: 'Exception during getting terms and conditions',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    })

    const { activeStep } = storeToRefs(corporateRegistrationStore)
    const isSubmitButtonDisabled = computed(() => {
      return (
        !hasFormFields.value ||
        isFormInvalid.value ||
        !isAddressReady.value ||
        addressErrorMessage.value
      )
    })

    const goBack = () => router.push(stepConfig.routes.backButton)
    // Address fields event
    const onInput = (address) => {
      const trimmedAddress = address.trim()
      formattedAddress.value = trimmedAddress
    }
    const onSelect = async (address) => {
      companyDetailsStore[stepConfig.dispatchSaveAddressTo] = address
      address.provinceName = address.provinceName.replace(/Wp\s/, '')
      let state = await corporateRegistrationStore.mapStateValue(address.provinceName)
      // set state to null if not mapped correctly
      const stateLength = !state ? state.length : 0
      state = stateLength <= 3 ? state : null
      const [streetName, streetType] = extractStreetNameAndStreetType(address.street)
      const formData = [
        // free-format address
        { id: 'line1', value: address.addressLine1 },
        { id: 'line2', value: address.addressLine2 },
        { id: 'line3', value: address.addressLine3 },
        // fixed-format address
        { id: 'streetname', value: streetName },
        { id: 'streetnumber', value: address.buildingNumber },
        { id: 'streettype', value: streetType },
        { id: 'unit', value: address.subBuilding },
        { id: 'area', value: address?.area },
        // shared
        { id: 'city', value: address.city },
        { id: 'state', value: state },
        { id: 'postalcode', value: address.postCode },
        { id: 'country', value: address.countryCode },
      ]
      companyAddress.value = formData
      formattedAddress.value = useAddressFormat(address, store)
      isSelectedAddress.value = true
      isAddressReady.value = true
    }
    const onAddressClear = () => {
      formattedAddress.value = ''
      isSelectedAddress.value = false
      isAddressReady.value = false
    }
    watch(formattedAddress, (formattedAddress) => {
      validateAddress(formattedAddress)
    })
    const countryConfig = computed(() =>
      countriesStore.getCountryConfigByCountryCode(companyCountry)
    )
    const validateAddress = (address) => {
      const regex = new RegExp(countryConfig.value.addressRegex)
      const isValid = regex.test(address)
      if (isValid) {
        addressErrorMessage.value = ''
      } else {
        addressErrorMessage.value = 'The address you entered is invalid. Please try again'
      }
    }
    // Check if company type is soft/hard opt-in
    const marketingPromptCompanies = registrationStore.getIsSoleCompanyType
    const hardOptInCountries = ['CA', 'AU', 'NF', 'NZ', 'CK']
    if (marketingPromptCompanies || hardOptInCountries.includes(companyCountry)) {
      allowMarketingOptIn.value = true
    }

    // Set true if the value is soft opt-in
    if (!allowMarketingOptIn.value) {
      marketingOptIn.value = true
    }
    // Submit registration
    const submit = async () => {
      try {
        // Preserve address
        registrationStore.formattedAddress = formattedAddress.value
        // Update consent after press the submit button.
        corporateRegistrationStore.setMarketingOptIn(marketingOptIn.value)
        // Send company fields to corp-bff
        const companyDataResponse = await corporateRegistrationStore.submitFieldData({
          group: 'company',
          formFields,
        })
        // Check if registration number is duplicated
        if (companyDataResponse && companyDataResponse.length > 0) {
          // Get duplicate company
          const duplicateCompany = companyDataResponse.find(
            (error) =>
              error.id === 'registrationnumber' &&
              error.errors.find((suberror) => suberror.code === 'DUPCOMPANY_005')
          )
          // If duplicated route user to user reg
          if (duplicateCompany) {
            // Send event if company is duplicate
            analyticsStore.track({
              event: 'Duplicate account error triggered',
              traits: {
                code: duplicateCompany.errors.find((error) => error).code,
                description: duplicateCompany.errors.find((error) => error).description,
              },
            })
            router.push({
              name: 'RegisterError',
              params: {
                errorType: 'duplicate',
              },
            })
            // Show error data
            corporateRegistrationStore.showServerErrors({
              companyDataResponse,
              formFields,
            })
            return
          }
        }
        // Save legal
        if (receiveDocuments.value) {
          await corporateRegistrationStore.submitFieldData({
            group: 'legal',
            formFields: [{ id: 'consenttoreceivedocuments', value: receiveDocuments.value }],
            send: false,
          })
          await corporateRegistrationStore.saveFormFields({
            group: 'legal',
            formFields: [{ id: 'consenttoreceivedocuments', value: receiveDocuments.value }],
          })
        }
        // Save company fields
        await corporateRegistrationStore.saveFormFields({
          group: 'company',
          formFields,
        })

        await corporateRegistrationStore.submitFieldData({
          group: 'registeredaddress',
          formFields: companyAddress.value,
          send: false,
        })
        await corporateRegistrationStore.saveFormFields({
          group: 'registeredaddress',
          formFields: companyAddress.value,
        })
        // Send business details event
        analyticsStore.track({
          event: 'Business Details Completed',
          traits: {
            registrationNumber: getFieldValue('registrationnumber', formFields),
            registrationName: getFieldValue('registeredname', formFields),
            referenceSource: 'XEMT Business',
            dateOfIncorporation: getFieldValue('incorporationdate', formFields),
            email: authStore.lastLogin,
          },
        })
        // Send all stored fields
        submitting.value = true
        await corporateRegistrationStore.submitAllFieldsData()
        // Agree t&c until routing to submit-reg page
        await corporateRegistrationStore.agreeTermsAndConditions(termsVersion.value)
        // Terms and conditions event
        analyticsStore.track({
          event: 'Terms & Conditions Accepted',
          traits: {
            privacyPolicyRegion: termsVersion.value,
            tcRegion: termsVersion.value,
            referenceSource: 'XEMT Business',
            email: authStore.lastLogin,
            directorSelected: true,
            marketingOptIn: marketingOptIn.value,
          },
        })
        // Routing user to submit-reg page
        router.push(stepConfig.routes.submitRegistration)
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submitting business details',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    }

    return {
      mq: reactive(useMediaQuery()),
      activeStep,
      allowMarketingOptIn,
      marketingPromptCompanies,
      formFields,
      isLoading,
      isSubmitButtonDisabled,
      stepConfig,
      formattedAddress,
      isSelectedAddress,
      isAddressReady,
      addressErrorMessage,
      companyCountry,
      marketingOptIn,
      termsUrl,
      privacyUrl,
      target,
      submitting,
      onInput,
      onSelect,
      onAddressClear,
      goBack,
      submit,
      authorizedStr,
    }
  },
}
</script>

<style lang="postcss" scoped>
.sub-card {
  @apply flex justify-center align-baseline;
  @apply border-blue-lighter border rounded-2xl;
  @apply shadow-ria-1;
  @apply my-6 p-4;
}

.sub-card-left {
  @apply self-start;
  padding-top: 0.2rem;
}
.sub-card-right {
  @apply flex-col gap-2.5 ml-2;
}

.business-details-card {
  ::v-deep .card-content {
    min-height: 100px;
    padding-bottom: 0px !important;
  }

  .link {
    @apply font-normal;
  }
}
.opt-in-div {
  @apply justify-center;
  @apply my-6;
  @apply p-0;
  .sub-opt-in-div-left {
    @apply flex items-baseline;
    padding-top: 0.6875rem;
  }
  div {
    @apply flex gap-0;
    @apply my-3;

    .sub-card-right {
      @apply flex-col gap-2.5 ml-2;
    }
  }
}
.submit-button {
  @apply rounded-lg;
  @apply w-full;
  @apply border-none;
  @screen sm {
    @apply w-auto;
    @apply border-solid;
  }
}
</style>
